var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "videoContainer", staticClass: "videoYoutube" },
    [
      _c("UcContextUi", { attrs: { name: "YouTube", "keep-true": "" } }, [
        _c("iframe", {
          attrs: {
            type: "text/html",
            src:
              "https://www.youtube.com/embed/" +
              _vm.youTubeId +
              "?controls=0&showinfo=0&rel=0" +
              _vm.isMuted +
              _vm.isAutoplay +
              _vm.isLoop,
            frameborder: "0",
            allowfullscreen: "",
          },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }