var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "videoContainer", staticClass: "videoVimeo" },
    [
      _c("UcContextUi", { attrs: { name: "Vimeo", "keep-true": "" } }, [
        _c("iframe", {
          ref: "videoIframe",
          attrs: {
            type: "text/html",
            height: "315",
            width: "560",
            src:
              "https://player.vimeo.com/video/" +
              _vm.vimeoId +
              "?&byline=0&portrait=0" +
              _vm.isMuted +
              _vm.isAutoplay +
              _vm.isLoop,
            frameborder: "0",
            allowfullscreen: "",
          },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }