

































import {forEach, isBool, isBrowser, isEmpty, isUndefined, PlainObject} from '@labor-digital/helferlein';
import Button from '../../../ContentElement/Persona/Button/Button.vue';
import Btn from '../Btn/Btn.vue';

export default {
    name: 'UcContextUi',
    components: {Btn, Button},
    props: {
        /**
         * The name of the service we should keep track of
         */
        name: {
            type: String,
            required: true
        }
    },

    data()
    {
        return {
            privacyIsAllowed: false
        };
    },
    methods: {
        initConsent()
        {
            if (!window.UC_UI) {
                setTimeout(this.initConsent, 100);
                return;
            }

            const services: Array<PlainObject> = window.UC_UI.getServicesBaseInfo();
            forEach(services, (service) => {
                if (service.name === this.name) {
                    this.privacyIsAllowed = service.consent.status;
                    this.$emit('privacy:update', service.consent.status, !service.consent.status);
                }
            });

        },

        acceptOnce()
        {
            if (!this.privacyIsAllowed) {
                this.privacyIsAllowed = true;
                this.$emit('privacy:update', true, false);
            }
        },

        acceptService()
        {
            if (!isEmpty(window.UC_UI)) {
                const services: Array<PlainObject> = window.UC_UI.getServicesBaseInfo();
                const service = services.filter((service) => service.name === this.name);

                if (isEmpty(service[0])) {
                    console.error('ERROR: Service ' + this.name + ' does not exist!');
                    return;
                }

                window.UC_UI.acceptService(service[0].id);
                this.$emit('privacy:update', services[this.name], !services[this.name]);
            }
        },

        /**
         * Internal watcher to keep track of the google maps consent field
         * @param services
         */
        ucWatcher(services?: { [id: string]: boolean })
        {
            if (isUndefined(services[this.name]) && isBool(services[this.name])) {
                console.error('ERROR: Service ' + this.name + ' does not exist!');
                return;
            }

            this.privacyIsAllowed = services[this.name];
            this.$emit('privacy:update', services[this.name], !services[this.name]);
        }
    },
    mounted()
    {
        if (isBrowser()) {
            window.addEventListener('ucEvent', (event) => this.ucWatcher(event.detail));
            this.initConsent();
        }
    },
    beforeDestroy()
    {
        if (isBrowser()) {
            window.addEventListener('ucEvent', (event) => this.ucWatcher(event.detail));
        }
    }
};
