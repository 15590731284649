




























import {isNull} from '@labor-digital/helferlein/lib/Types/isNull';
import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import ImageSlider from '../../../Component/Misc/Image/ImageSlider/ImageSlider.vue';
import UcContextUi from '../../../Component/Misc/UcContextUi/UcContextUi.vue';
import VideoEmbed from '../../../Component/Misc/Video/VideoEmbed/VideoEmbed.vue';
import VideoVimeo from '../../../Component/Misc/Video/VideoVimeo/VideoVimeo.vue';
import VideoYoutube from '../../../Component/Misc/Video/VideoYoutube/VideoYoutube.vue';

export default {
    name: 'Video',
    components: {UcContextUi, VideoVimeo, VideoYoutube, VideoEmbed, ImageSlider},
    props: {
        context: null as ContentElementContext
    },
    data()
    {
        return {
            privacyAllowed: false
        };
    },
    computed: {
        video()
        {
            const video = this.context.data.get('video', null);
            return isNull(video) ? null : video[0];
        },
        isVimeo()
        {
            return isNull(this.video) ? false : this.video.video.isVimeo;
        },
        isYouTube()
        {
            return isNull(this.video) ? false : this.video.video.isYouTube;
        },
        poster()
        {
            const poster = this.context.data.get('poster', null);
            return isNull(poster) ? null : poster[0].url;
        }
    },
    methods: {}
};
