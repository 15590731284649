var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "UcContextUi", attrs: { "data-name": _vm.name } },
    [
      _vm.privacyIsAllowed
        ? _vm._t("default")
        : _c("div", { staticClass: "UcContextUi__consent" }, [
            _c("p", [
              _vm._v(
                _vm._s(_vm.$t("uc.contextualConsent.description", [this.name]))
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "UcContextUi__actions" },
              [
                _c(
                  "btn",
                  {
                    nativeOn: {
                      click: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "na",
                            undefined,
                            $event.key,
                            undefined
                          )
                        ) {
                          return null
                        }
                        return _vm.acceptOnce()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("uc.contextualConsent.once")))]
                ),
                _vm._v(" "),
                _c(
                  "btn",
                  {
                    nativeOn: {
                      click: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "na",
                            undefined,
                            $event.key,
                            undefined
                          )
                        ) {
                          return null
                        }
                        return _vm.acceptService()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("uc.contextualConsent.always")))]
                ),
              ],
              1
            ),
          ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }