import { render, staticRenderFns } from "./VideoYoutube.vue?vue&type=template&id=31de6e03&scoped=true&"
import script from "./VideoYoutube.vue?vue&type=script&lang=ts&"
export * from "./VideoYoutube.vue?vue&type=script&lang=ts&"
import style0 from "VideoYoutube.sass?vue&type=style&index=0&id=31de6e03&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31de6e03",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('31de6e03')) {
      api.createRecord('31de6e03', component.options)
    } else {
      api.reload('31de6e03', component.options)
    }
    module.hot.accept("./VideoYoutube.vue?vue&type=template&id=31de6e03&scoped=true&", function () {
      api.rerender('31de6e03', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/Component/Misc/Video/VideoYoutube/VideoYoutube.vue"
export default component.exports