var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _vm.video
      ? _c(
          "div",
          { staticClass: "video" },
          [
            !_vm.isVimeo && !_vm.isYouTube
              ? _c("video-embed", {
                  attrs: {
                    "media-file": _vm.video.url,
                    poster: _vm.poster,
                    "is-hero": false,
                    muted: false,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.isYouTube
              ? _c("video-youtube", {
                  attrs: { "you-tube-id": _vm.video.video.videoId },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.isVimeo
              ? _c("video-vimeo", {
                  attrs: { "vimeo-id": _vm.video.video.videoId },
                })
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }