






























import UcContextUi from '../../UcContextUi/UcContextUi.vue';

export default {
    name: 'VideoYoutube',
    components: {UcContextUi},
    props: {
        autoplay: {
            type: Boolean,
            default: false
        },
        loop: {
            type: Boolean,
            default: true
        },
        muted: {
            type: Boolean,
            default: false
        },
        youTubeId: String
    },
    computed: {
        isAutoplay(): string
        {
            return this.autoplay ? '&autoplay=1' : '';
        },
        isLoop(): string
        {
            return this.autoplay ? '&loop=1' : '';
        },
        isMuted(): string
        {
            return this.muted ? '&mute=1' : '';
        }
    }
};
